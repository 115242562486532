/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import HtmlInput from './HtmlInput';

/**
 * The PersonIdType model module.
 * @module model/PersonIdType
 * @version 1.3.3
 */
class PersonIdType {
    /**
     * Constructs a new <code>PersonIdType</code>.
     * Person ID type.
     * @alias module:model/PersonIdType
     * @param id {Number} Internal database ID.
     * @param name {String} Name of personal ID. Non-localized value for developers only.
     * @param input {module:model/HtmlInput} 
     */
    constructor(id, name, input) { 
        
        PersonIdType.initialize(this, id, name, input);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, input) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['input'] = input;
    }

    /**
     * Constructs a <code>PersonIdType</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PersonIdType} obj Optional instance to populate.
     * @return {module:model/PersonIdType} The populated <code>PersonIdType</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PersonIdType();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('input')) {
                obj['input'] = HtmlInput.constructFromObject(data['input']);
            }
        }
        return obj;
    }


}

/**
 * Internal database ID.
 * @member {Number} id
 */
PersonIdType.prototype['id'] = undefined;

/**
 * Name of personal ID. Non-localized value for developers only.
 * @member {String} name
 */
PersonIdType.prototype['name'] = undefined;

/**
 * @member {module:model/HtmlInput} input
 */
PersonIdType.prototype['input'] = undefined;






export default PersonIdType;

