import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import moment from 'moment';
import {
  FormGroup,
  CustomInput,
  Label,
  FormFeedback,
} from 'reactstrap';
import {
  bool,
  string,
  number,
  shape,
  arrayOf,
} from 'prop-types';

const messages = defineMessages({
  Label: {
    defaultMessage: 'Purpose',
  },
  EmptyOptionAriaLabel: {
    defaultMessage: 'Not selected',
  },
});


const PurposeSelector = ({
  purposes,
  disabled,
  required,
  isInvalid,
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormGroup>
      <Label for="purposeId">
        {formatMessage(messages.Label)}
      </Label>
      <Field name="purposeId">
        {({ field }) => (
          <CustomInput
            /* eslint-disable react/jsx-props-no-spreading */
            {...field}
            /* eslint-enable react/jsx-props-no-spreading */
            type="select"
            id="purposeId"
            invalid={isInvalid}
            required={required}
            disabled={disabled}
          >
            <option
              aria-label={formatMessage(messages.EmptyOptionAriaLabel)}
              value=""
            />
            {purposes.map(({
              id,
              name,
              duration,
              option_label: optionLabel,
            }) => (
              <option
                key={name}
                value={id}
              >
                {optionLabel}
                {' ('}
                {moment.duration(duration).humanize()}
                )
              </option>
            ))}
          </CustomInput>
        )}
      </Field>
      <FormFeedback>
        <ErrorMessage name="purposeId" />
      </FormFeedback>
    </FormGroup>
  );
};

PurposeSelector.propTypes = {
  purposes: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      duration: string,
      option_label: string,
    }),
  ).isRequired,
  disabled: bool,
  required: bool,
  isInvalid: bool,
};

PurposeSelector.defaultProps = {
  disabled: false,
  required: false,
  isInvalid: false,
};

export default PurposeSelector;
