import { ApiClient, MainApi } from '../../api-client/src';
import ApiPayloadKey from './ApiPayloadKey';
import {
  getPassOptionsCall,
  requestNewPassCall,
  findPassCall,
} from '../actionCreators';

export default (store) => (next) => (action) => {
  const { type, payload } = action;
  const { settings: { locale } } = store.getState();
  if (ApiPayloadKey in payload) {
    const {
      successActionCreator,
      errorActionCreator,
      options,
      arguments: apiRequestArguments,
      executeRecaptcha: executeRecaptchaCallback,
    } = payload[ApiPayloadKey];
    const apiClient = new ApiClient();
    const serverId = (process.env.NODE_ENV !== 'production') ? 0 : 1;
    apiClient.basePath = apiClient.getBasePathFromSettings(serverId);
    const defaultErrorMessage = 'Unknown Error Occurred';
    const defaultErrorCode = 1000;

    const callGetPassOptions = () => {
      const apiInstance = new MainApi(apiClient);
      apiInstance.getPassOptions({
        acceptLanguage: locale,
      }).then((data) => {
        store.dispatch(successActionCreator(data));
      }, ({
        error: {
          code: errorCode = defaultErrorCode,
          message: errorMessage = defaultErrorMessage,
        },
      }) => {
        store.dispatch(errorActionCreator({ errorCode, errorMessage }));
      });
    };

    const callRequestNewPass = async (opts, executeRecaptcha = null) => {
      const token = (typeof executeRecaptcha === 'function') ? await executeRecaptcha('request_pass') : null;
      const apiInstance = new MainApi(apiClient);
      apiInstance.requestNewPass({
        ...opts,
        inlineObject: {
          ...opts.inlineObject,
          'g-recaptcha-response': token,
        },
        acceptLanguage: locale,
      }).then((data) => {
        store.dispatch(successActionCreator(data));
      }, ({
        error: {
          code: errorCode = defaultErrorCode,
          message: errorMessage = defaultErrorMessage,
        },
        status,
        body,
      }) => {
        if (status === 422) {
          const { invalid_fields: invalidFields = [] } = body;
          store.dispatch(errorActionCreator({
            errorCode: body.error_code,
            errorMessage: body.error_message,
            invalidFields,
          }));
          return;
        }
        if (status === 400) {
          store.dispatch(errorActionCreator({
            errorCode: body.error_code,
            errorMessage: body.error_message,
          }));
          return;
        }
        store.dispatch(errorActionCreator({ errorCode, errorMessage }));
      });
    };

    const callFindPass = (params) => {
      const apiInstance = new MainApi(apiClient);
      const callParams = [...params];
      callParams[3] = { acceptLanguage: locale };
      apiInstance.findPass(...callParams).then((data) => {
        store.dispatch(successActionCreator(data));
      }, ({
        error: {
          code: errorCode = defaultErrorCode,
          message: errorMessage = defaultErrorMessage,
        },
        status,
        body,
      }) => {
        if (status === 404) {
          store.dispatch(errorActionCreator({
            errorCode: body.error_code,
            errorMessage: body.error_message,
          }));
          return;
        }
        store.dispatch(errorActionCreator({ errorCode, errorMessage }));
      });
    };

    switch (type) {
      case getPassOptionsCall.toString():
        callGetPassOptions();
        break;
      case requestNewPassCall.toString():
        callRequestNewPass(options, executeRecaptchaCallback);
        break;
      case findPassCall.toString():
        callFindPass(apiRequestArguments);
        break;
      default:
        // do nothing, unknown API call
    }
  }

  return next(action);
};
