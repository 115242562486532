import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { number, string, instanceOf } from 'prop-types';
import { Row, Col } from 'reactstrap';

const messages = defineMessages({
  ID: {
    defaultMessage: 'ID',
  },
  Status: {
    defaultMessage: 'Status',
  },
  StatusActive: {
    defaultMessage: 'Active',
  },
  StatusExpired: {
    defaultMessage: 'Expired',
  },
  StatusNotActive: {
    defaultMessage: 'Not active yet',
  },
  ActiveFrom: {
    defaultMessage: 'Active from',
  },
  ExpiresAt: {
    defaultMessage: 'Expires at',
  },
  Purpose: {
    defaultMessage: 'Purpose',
  },
  QrCode: {
    defaultMessage: 'QR code',
  },
  QrCodeAlt: {
    defaultMessage: 'QR code of issued pass',
  },
});

const renderPassStatus = (from, expires, translate) => {
  const now = moment();
  if (now.isBetween(from, expires)) {
    // active
    return (
      <span className="text-success">
        {translate(messages.StatusActive)}
      </span>
    );
  }

  if (now.isSameOrAfter(expires)) {
    // expired
    return (
      <span className="text-danger">
        {translate(messages.StatusExpired)}
      </span>
    );
  }

  // not active yet
  return <>{translate(messages.StatusNotActive)}</>;
};

const PassItem = ({
  id,
  from,
  expires,
  personIdLabel,
  personIdNumber,
  purposeLabel,
  qrcodeSrc,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="pass-item">
      <Row>
        <Col
          md={{ size: 5, offset: 1 }}
          lg={{ size: 4, offset: 2 }}
        >
          <dl>
            <dt>
              {formatMessage(messages.ID)}
            </dt>
            <dd>
              {id}
            </dd>
            <dt>
              {formatMessage(messages.Status)}
            </dt>
            <dd>
              {renderPassStatus(from, expires, formatMessage)}
            </dd>
            <dt>
              {formatMessage(messages.ActiveFrom)}
            </dt>
            <dd>
              <time dateTime={moment(from).format()}>
                {moment(from).format('LLL')}
              </time>
            </dd>
            <dt>
              {formatMessage(messages.ExpiresAt)}
            </dt>
            <dd>
              <time dateTime={moment(expires).format()}>
                {moment(expires).format('LLL')}
              </time>
            </dd>
            <dt>
              {personIdLabel}
            </dt>
            <dd>
              {personIdNumber}
            </dd>
            <dt>
              {formatMessage(messages.Purpose)}
            </dt>
            <dd>
              {purposeLabel}
            </dd>
          </dl>
        </Col>
        <Col
          md="5"
          lg="4"
        >
          <dt>
            {formatMessage(messages.QrCode)}
          </dt>
          <dd>
            <img
              className="img-fluid"
              src={qrcodeSrc}
              width="300"
              height="300"
              alt={formatMessage(messages.QrCodeAlt)}
            />
          </dd>
        </Col>
      </Row>
    </div>
  );
};

PassItem.propTypes = {
  id: number.isRequired,
  from: instanceOf(Date).isRequired,
  expires: instanceOf(Date).isRequired,
  personIdLabel: string.isRequired,
  personIdNumber: string.isRequired,
  purposeLabel: string.isRequired,
  qrcodeSrc: string.isRequired,
};

export default PassItem;
