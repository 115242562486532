import React from 'react';
import { string, func, bool } from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';

const LangSwitch = ({ currentLang, setLang, disabled }) => (
  <>
    <NavItem>
      <NavLink
        className="btn btn-link"
        active={currentLang === 'en'}
        onClick={() => {
          setLang('en');
        }}
        disabled={disabled}
        tag="button"
      >
        English
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className="btn btn-link"
        active={currentLang === 'ru'}
        onClick={() => {
          setLang('ru');
        }}
        disabled={disabled}
        tag="button"
      >
        Русский
      </NavLink>
    </NavItem>
  </>
);

LangSwitch.propTypes = {
  currentLang: string.isRequired,
  setLang: func.isRequired,
  disabled: bool,
};

LangSwitch.defaultProps = {
  disabled: false,
};

export default LangSwitch;
