import React, { Component, createRef } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { Formik } from 'formik';
import { camelize } from 'humps';
import { Button } from 'reactstrap';
import {
  arrayOf,
  shape,
  string,
  number,
  bool,
  func,
  oneOfType,
} from 'prop-types';

import PersonIdTypeLoader from './PersonIdTypeLoader';
import PurposeLoader from './PurposeLoader';
import PersonIdNumberInput from './PersonIdNumberInput';

const messages = defineMessages({
  EmptyPurposeId: {
    defaultMessage: 'Select purpose.',
  },
  EmptyPersonIdType: {
    defaultMessage: 'Select person ID type.',
  },
  EmptyPersonIdNumber: {
    defaultMessage: 'Enter person ID number.',
  },
  PersonIdNumberDefaultHelpText: {
    defaultMessage: 'Please, select Person ID type first.',
  },
  FormLegend: {
    defaultMessage: 'Request New Pass',
  },
  FormSubmitLabel: {
    defaultMessage: 'Send',
  },
});

class PassRequest extends Component {
  constructor(props) {
    super(props);

    this.formikRef = createRef();
  }

  componentDidUpdate({ errorCode: prevErrorCode }) {
    const { isPending, errorCode, invalidFields = [] } = this.props;
    const { setErrors, setSubmitting, isSubmitting } = this.formikRef.current;
    if (isPending !== isSubmitting) {
      setSubmitting(isPending);
    }
    if (prevErrorCode === null && errorCode === 422 && invalidFields.length) {
      const errors = invalidFields.reduce((prev, {
        field_name: fieldName,
        field_error_message: fieldErrorMessage,
      }) => ({
        ...prev,
        [camelize(fieldName)]: fieldErrorMessage,
      }), {});
      setErrors(errors);
    }
  }

  render() {
    const {
      personIdTypes,
      isPending,
      onSubmit,
      intl: {
        formatMessage,
      },
    } = this.props;

    return (
      <Formik
        innerRef={this.formikRef}
        initialValues={{
          personIdType: '',
          personIdNumber: '',
          purposeId: '',
        }}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};

          if (values.purposeId === '') {
            errors.purposeId = formatMessage(messages.EmptyPurposeId);
          }
          if (values.personIdType === '') {
            errors.personIdType = formatMessage(messages.EmptyPersonIdType);
          }
          if (values.personIdNumber === '') {
            errors.personIdNumber = formatMessage(messages.EmptyPersonIdNumber);
          }

          return errors;
        }}
      >
        {({
          values: { personIdType },
          touched,
          errors,
          handleSubmit,
        }) => {
          const defaultPersonIdNumberHelp = formatMessage(messages.PersonIdNumberDefaultHelpText);
          const {
            input: {
              label: selectedLabel = null,
              aria_describedby: selectedAriaDescribedby = defaultPersonIdNumberHelp,
              placeholder: selectedPlaceholder = null,
              maxlength: selectedMaxLength = null,
              pattern: selectedPattern = null,
            } = {},
          } = personIdTypes.find(({ id }) => id.toString() === personIdType) || {};
          const { hasOwnProperty } = Object.prototype;
          const purposeIdInvalid = hasOwnProperty.call(errors, 'purposeId');
          const personIdTypeInvalid = hasOwnProperty.call(errors, 'personIdType');
          const personIdNumberInvalid = hasOwnProperty.call(errors, 'personIdNumber');
          const hidePersonIdNumberHelp = hasOwnProperty.call(touched, 'personIdNumber') && personIdNumberInvalid;

          return (
            <form className="form-pass-request" noValidate onSubmit={handleSubmit}>
              <legend>
                {formatMessage(messages.FormLegend)}
              </legend>
              <fieldset
                disabled={isPending}
              >
                <PurposeLoader
                  isInvalid={purposeIdInvalid}
                  required
                />
                <PersonIdTypeLoader
                  isInvalid={personIdTypeInvalid}
                  required
                />
                <PersonIdNumberInput
                  inputLabel={selectedLabel}
                  inputAriaDescribedby={!hidePersonIdNumberHelp ? selectedAriaDescribedby : null}
                  disabled={personIdType === 'null'}
                  placeholder={selectedPlaceholder}
                  maxLength={selectedMaxLength}
                  pattern={selectedPattern}
                  isInvalid={personIdNumberInvalid}
                  required
                />
              </fieldset>
              <Button
                type="submit"
                color="primary"
                disabled={isPending}
                className="float-right"
              >
                {formatMessage(messages.FormSubmitLabel)}
              </Button>
            </form>
          );
        }}
      </Formik>
    );
  }
}

PassRequest.propTypes = {
  personIdTypes: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      input: shape({
        label: string,
        aria_describedby: string,
        placeholder: string,
        maxlength: number,
        pattern: string,
      }),
    }),
  ).isRequired,
  onSubmit: func.isRequired,
  isPending: bool,
  errorCode: number,
  invalidFields: arrayOf(
    shape({
      field_name: string,
      field_value: oneOfType([string, number]),
      field_error_message: string,
    }),
  ),
};

PassRequest.defaultProps = {
  isPending: false,
  errorCode: null,
  invalidFields: null,
};

export default injectIntl(PassRequest);
