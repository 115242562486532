import React, { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Redirect, useLocation } from 'react-router-dom';
import { parse } from 'querystring';
import { useSelector, useDispatch } from 'react-redux';

import ErrorAlert from '../components/ErrorAlert';
import PassItem from '../components/PassItem';
import { requestNewPassReset, findPassCall } from '../redux/actionCreators';
import {
  getLocale,
  canCallFindPass,
  getPasses,
  getFindPassError,
} from '../redux/selectors';

const messages = defineMessages({
  Title: {
    defaultMessage: 'Issued Pass',
  },
});

const Pass = () => {
  const { formatMessage } = useIntl();
  const { search = '?' } = useLocation();
  const query = parse(search.replace('?', ''));
  const { hasOwnProperty } = Object.prototype;
  const everyCallback = (current) => hasOwnProperty.call(query, current) && query[current];
  const {
    pass_id: urlPassId,
    person_id_type: urlPersonIdType,
    person_id_number: urlPersonIdNumber,
  } = query;
  const locale = useSelector(getLocale);
  const isFetchNotFinished = useSelector(canCallFindPass);
  const passes = useSelector(getPasses);
  const { errorCode, errorMessage } = useSelector(getFindPassError);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPass = () => {
      if (isFetchNotFinished) {
        // pass isn't loaded, fetch
        dispatch(findPassCall(urlPassId, urlPersonIdType, urlPersonIdNumber));
      }
    };
    fetchPass();
  }, [locale, isFetchNotFinished, dispatch, urlPassId, urlPersonIdType, urlPersonIdNumber]);

  if (['pass_id', 'person_id_type', 'person_id_number'].every(everyCallback) === false) {
    dispatch(requestNewPassReset());
    return <Redirect to="/" />;
  }

  const renderPass = (id, items) => {
    if (id in items) {
      const {
        id: passId,
        from,
        expires,
        person_id: {
          number: personIdNumber,
          label: personIdLabel,
        },
        purpose: {
          option_label: purposeLabel,
        },
        qrcode_src: qrcodeSrc,
      } = items[id];

      return (
        <PassItem
          id={passId}
          from={from}
          expires={expires}
          personIdNumber={personIdNumber}
          personIdLabel={personIdLabel}
          purposeLabel={purposeLabel}
          qrcodeSrc={qrcodeSrc}
        />
      );
    }

    return null;
  };

  return (
    <section className="check-page">
      <h1 className="h2">
        {formatMessage(messages.Title)}
      </h1>
      <ErrorAlert
        errorCode={errorCode}
        errorMessage={errorMessage}
      />
      {renderPass(urlPassId, passes)}
    </section>
  );
};

export default Pass;
