export const getLocale = ({ settings: { locale } }) => locale;

export const getIsPending = ({ settings: { isPending } }) => isPending;

export const canCallFindPass = ({
  settings: { isPending },
  passRequest: { errorCode, errorMessage, success },
}) => (
  success === false
  && isPending === false
  && errorCode === null
  && errorMessage === null
);

export const canCallGetPassOptions = ({
  settings: { isPending },
  entities: { personIdTypes, purposes },
  passOptions: { errorCode, errorMessage },
}) => (
  isPending === false
  && personIdTypes.length === 0
  && purposes.length === 0
  && errorCode === null
  && errorMessage === null
);

export const getPersonIdTypes = ({
  entities: { personIdTypes },
}) => personIdTypes;

export const getPurposes = ({
  entities: { purposes },
}) => purposes;

export const getPasses = ({
  entities: { passes },
}) => passes;

export const getFindPassError = ({
  passRequest: { errorCode, errorMessage },
}) => ({ errorCode, errorMessage });

export const getPassOptionsError = ({
  passOptions: { errorCode, errorMessage },
}) => ({ errorCode, errorMessage });
