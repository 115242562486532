/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PassPersonId model module.
 * @module model/PassPersonId
 * @version 1.3.3
 */
class PassPersonId {
    /**
     * Constructs a new <code>PassPersonId</code>.
     * Person ID of issued pass.
     * @alias module:model/PassPersonId
     * @param type {Number} Person ID type from #/components/schemas/PersonIdType/id 
     * @param _number {String} Full number of person ID.
     * @param label {String} Person ID name. Localized value.
     */
    constructor(type, _number, label) { 
        
        PassPersonId.initialize(this, type, _number, label);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, type, _number, label) { 
        obj['type'] = type;
        obj['number'] = _number;
        obj['label'] = label;
    }

    /**
     * Constructs a <code>PassPersonId</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PassPersonId} obj Optional instance to populate.
     * @return {module:model/PassPersonId} The populated <code>PassPersonId</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PassPersonId();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'Number');
            }
            if (data.hasOwnProperty('number')) {
                obj['number'] = ApiClient.convertToType(data['number'], 'String');
            }
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Person ID type from #/components/schemas/PersonIdType/id 
 * @member {Number} type
 */
PassPersonId.prototype['type'] = undefined;

/**
 * Full number of person ID.
 * @member {String} number
 */
PassPersonId.prototype['number'] = undefined;

/**
 * Person ID name. Localized value.
 * @member {String} label
 */
PassPersonId.prototype['label'] = undefined;






export default PassPersonId;

