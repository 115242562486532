import { combineReducers } from 'redux';
import settings from './settings';
import passOptions from './passOptions';
import passRequest from './passRequest';
import entities from './entities';

export default combineReducers({
  settings,
  entities,
  passOptions,
  passRequest,
});
