/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The HtmlInput model module.
 * @module model/HtmlInput
 * @version 1.3.3
 */
class HtmlInput {
    /**
     * Constructs a new <code>HtmlInput</code>.
     * HTML &#x60;&lt;input&gt;&#x60; attributes.
     * @alias module:model/HtmlInput
     * @param label {String} Defines a label for an `<input>` element. Localized value. 
     * @param ariaDescribedby {String} `<input>` ARIA attribute `aria-describedby`. Localized value. Meaningful description of person ID. How to type it into the form. How ID number should look like. Up to 280 chars. 
     * @param maxlength {Number} `<input>` attribute `maxlength`. The input `maxlength` attribute specifies the maximum number of characters allowed in an input field. **Note**: When a `maxlength` is set, the input field will not accept more than the specified number of characters. However, this attribute does not provide any feedback. So, if you want to alert the user, you must write JavaScript code. 
     */
    constructor(label, ariaDescribedby, maxlength) { 
        
        HtmlInput.initialize(this, label, ariaDescribedby, maxlength);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, label, ariaDescribedby, maxlength) { 
        obj['label'] = label;
        obj['aria_describedby'] = ariaDescribedby;
        obj['maxlength'] = maxlength;
    }

    /**
     * Constructs a <code>HtmlInput</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/HtmlInput} obj Optional instance to populate.
     * @return {module:model/HtmlInput} The populated <code>HtmlInput</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new HtmlInput();

            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('aria_describedby')) {
                obj['aria_describedby'] = ApiClient.convertToType(data['aria_describedby'], 'String');
            }
            if (data.hasOwnProperty('placeholder')) {
                obj['placeholder'] = ApiClient.convertToType(data['placeholder'], 'String');
            }
            if (data.hasOwnProperty('maxlength')) {
                obj['maxlength'] = ApiClient.convertToType(data['maxlength'], 'Number');
            }
            if (data.hasOwnProperty('pattern')) {
                obj['pattern'] = ApiClient.convertToType(data['pattern'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Defines a label for an `<input>` element. Localized value. 
 * @member {String} label
 */
HtmlInput.prototype['label'] = undefined;

/**
 * `<input>` ARIA attribute `aria-describedby`. Localized value. Meaningful description of person ID. How to type it into the form. How ID number should look like. Up to 280 chars. 
 * @member {String} aria_describedby
 */
HtmlInput.prototype['aria_describedby'] = undefined;

/**
 * `<input>` attribute `placeholder`. The input `placeholder` attribute specifies short a hint that describes the expected value of an input field (a sample value or a short description of the expected format). 
 * @member {String} placeholder
 */
HtmlInput.prototype['placeholder'] = undefined;

/**
 * `<input>` attribute `maxlength`. The input `maxlength` attribute specifies the maximum number of characters allowed in an input field. **Note**: When a `maxlength` is set, the input field will not accept more than the specified number of characters. However, this attribute does not provide any feedback. So, if you want to alert the user, you must write JavaScript code. 
 * @member {Number} maxlength
 * @default 100
 */
HtmlInput.prototype['maxlength'] = 100;

/**
 * `<input>` attribute `pattern`. The input `pattern` attribute specifies a regular expression that the input field's value is checked against, when the form is submitted. 
 * @member {String} pattern
 */
HtmlInput.prototype['pattern'] = undefined;






export default HtmlInput;

