/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ApiErrorInvalidFields model module.
 * @module model/ApiErrorInvalidFields
 * @version 1.3.3
 */
class ApiErrorInvalidFields {
    /**
     * Constructs a new <code>ApiErrorInvalidFields</code>.
     * Invalid field.
     * @alias module:model/ApiErrorInvalidFields
     * @param fieldName {String} Name of invalid field.
     * @param fieldValue {String} Value of invalid field.
     * @param fieldErrorMessage {String} Error message of invalid field.
     */
    constructor(fieldName, fieldValue, fieldErrorMessage) { 
        
        ApiErrorInvalidFields.initialize(this, fieldName, fieldValue, fieldErrorMessage);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, fieldName, fieldValue, fieldErrorMessage) { 
        obj['field_name'] = fieldName;
        obj['field_value'] = fieldValue;
        obj['field_error_message'] = fieldErrorMessage;
    }

    /**
     * Constructs a <code>ApiErrorInvalidFields</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ApiErrorInvalidFields} obj Optional instance to populate.
     * @return {module:model/ApiErrorInvalidFields} The populated <code>ApiErrorInvalidFields</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ApiErrorInvalidFields();

            if (data.hasOwnProperty('field_name')) {
                obj['field_name'] = ApiClient.convertToType(data['field_name'], 'String');
            }
            if (data.hasOwnProperty('field_value')) {
                obj['field_value'] = ApiClient.convertToType(data['field_value'], 'String');
            }
            if (data.hasOwnProperty('field_error_message')) {
                obj['field_error_message'] = ApiClient.convertToType(data['field_error_message'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Name of invalid field.
 * @member {String} field_name
 */
ApiErrorInvalidFields.prototype['field_name'] = undefined;

/**
 * Value of invalid field.
 * @member {String} field_value
 */
ApiErrorInvalidFields.prototype['field_value'] = undefined;

/**
 * Error message of invalid field.
 * @member {String} field_error_message
 */
ApiErrorInvalidFields.prototype['field_error_message'] = undefined;






export default ApiErrorInvalidFields;

