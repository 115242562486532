import { handleActions } from 'redux-actions';
import moment from 'moment';

import {
  setLocale,
  getPassOptionsCall,
  getPassOptionsSuccess,
  getPassOptionsError,
  requestNewPassCall,
  requestNewPassSuccess,
  requestNewPassError,
  findPassCall,
  findPassSuccess,
  findPassError,
} from '../actionCreators';

const startLoadingReducer = (state) => ({ ...state, isPending: true });
const endLoadingReducer = (state) => ({ ...state, isPending: false });

export default handleActions(
  {
    [setLocale]: (state, { payload: { locale } }) => {
      moment.locale(locale);
      return {
        ...state,
        locale,
      };
    },

    [getPassOptionsCall]: startLoadingReducer,
    [getPassOptionsSuccess]: endLoadingReducer,
    [getPassOptionsError]: endLoadingReducer,

    [requestNewPassCall]: startLoadingReducer,
    [requestNewPassSuccess]: endLoadingReducer,
    [requestNewPassError]: endLoadingReducer,

    [findPassCall]: startLoadingReducer,
    [findPassSuccess]: endLoadingReducer,
    [findPassError]: endLoadingReducer,
  },
  {
    locale: 'en',
    isPending: false,
  },
);
