/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ApiErrorInvalidFields from './ApiErrorInvalidFields';

/**
 * The ApiError model module.
 * @module model/ApiError
 * @version 1.3.3
 */
class ApiError {
    /**
     * Constructs a new <code>ApiError</code>.
     * API error.
     * @alias module:model/ApiError
     * @param errorCode {Number} Error code.
     * @param errorMessage {String} Error message.
     */
    constructor(errorCode, errorMessage) { 
        
        ApiError.initialize(this, errorCode, errorMessage);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, errorCode, errorMessage) { 
        obj['error_code'] = errorCode;
        obj['error_message'] = errorMessage;
    }

    /**
     * Constructs a <code>ApiError</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ApiError} obj Optional instance to populate.
     * @return {module:model/ApiError} The populated <code>ApiError</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ApiError();

            if (data.hasOwnProperty('error_code')) {
                obj['error_code'] = ApiClient.convertToType(data['error_code'], 'Number');
            }
            if (data.hasOwnProperty('error_message')) {
                obj['error_message'] = ApiClient.convertToType(data['error_message'], 'String');
            }
            if (data.hasOwnProperty('invalid_fields')) {
                obj['invalid_fields'] = ApiClient.convertToType(data['invalid_fields'], [ApiErrorInvalidFields]);
            }
        }
        return obj;
    }


}

/**
 * Error code.
 * @member {Number} error_code
 */
ApiError.prototype['error_code'] = undefined;

/**
 * Error message.
 * @member {String} error_message
 */
ApiError.prototype['error_message'] = undefined;

/**
 * Description of invalid entities.
 * @member {Array.<module:model/ApiErrorInvalidFields>} invalid_fields
 */
ApiError.prototype['invalid_fields'] = undefined;






export default ApiError;

