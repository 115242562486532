import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import 'regenerator-runtime/runtime';

import configureStore from './redux/configureStore';
import RootRoute from './routes';

const store = configureStore();
library.add(faEnvelope, faGithub);

const App = () => (
  <Provider store={store}>
    <RootRoute />
  </Provider>
);

export default hot(App);
