import { handleActions } from 'redux-actions';

import {
  setLocale,
  requestNewPassReset,

  getPassOptionsCall,
  getPassOptionsSuccess,

  requestNewPassCall,
  requestNewPassSuccess,

  findPassCall,
  findPassSuccess,
} from '../actionCreators';

const initState = {
  passes: {},
  personIdTypes: [],
  purposes: [],
};

const resetReducer = () => initState;
const resetPassOptionsReducer = (state) => ({
  ...state,
  personIdTypes: initState.personIdTypes,
  purposes: initState.purposes,
});
const resetPassesReducer = (state) => ({
  ...state,
  passes: initState.passes,
});

const passOptionsSuccessReducer = (
  state, {
    payload: {
      data: {
        person_id_types: personIdTypes,
        purposes,
      },
    },
  },
) => ({
  ...state,
  personIdTypes,
  purposes,
});

const requestPassSuccessReducer = (state, { payload: { data } }) => ({
  ...state,
  passes: {
    ...state.passes,
    [data.id.toString()]: data,
  },
});

export default handleActions(
  {
    [setLocale]: resetReducer,

    [getPassOptionsCall]: resetPassOptionsReducer,
    [getPassOptionsSuccess]: passOptionsSuccessReducer,

    [requestNewPassCall]: resetPassesReducer,
    [requestNewPassSuccess]: requestPassSuccessReducer,
    [requestNewPassReset]: resetPassesReducer,

    [findPassCall]: resetPassesReducer,
    [findPassSuccess]: requestPassSuccessReducer,
  },
  initState,
);
