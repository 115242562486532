import { handleActions } from 'redux-actions';

import {
  setLocale,
  getPassOptionsCall,
  getPassOptionsError,
  getPassOptionsSuccess,
} from '../actionCreators';

const initState = {
  errorCode: null,
  errorMessage: null,
};

const resetReducer = () => initState;

const requestSuccessReducer = (state) => ({
  ...state,
  errorCode: null,
  errorMessage: null,
});

const requestErrorReducer = (
  state, {
    payload: {
      error: {
        errorCode,
        errorMessage,
      },
    },
  },
) => ({
  ...state,
  errorCode,
  errorMessage,
});

export default handleActions(
  {
    [setLocale]: resetReducer,
    [getPassOptionsCall]: resetReducer,
    [getPassOptionsError]: requestErrorReducer,
    [getPassOptionsSuccess]: requestSuccessReducer,
  },
  initState,
);
