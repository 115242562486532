import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { stringify } from 'querystring';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Card,
  CardBody,
  CardFooter,
  Col,
} from 'reactstrap';
import {
  string,
  shape,
  number,
  bool,
  arrayOf,
  func,
  oneOfType,
  objectOf,
} from 'prop-types';

import ErrorAlert from '../components/ErrorAlert';
import PassRequestForm from '../components/form/PassRequest';
import { requestNewPassCall } from '../redux/actionCreators';

const messages = defineMessages({
  Title: {
    defaultMessage: 'Quarantine Pass System',
  },
});

const NewPass = ({
  isPending,
  personIdTypes,
  passes,
  optionsErrorCode,
  optionsErrorMessage,
  submitErrorCode,
  submitErrorMessage,
  submitInvalidFields,
  submitSuccess,
  submitCreatedId,
  showHeadingAsterisk,
  dispatch,
}) => {
  const { formatMessage } = useIntl();
  const { hasOwnProperty } = Object.prototype;
  const result = (submitCreatedId && submitCreatedId in passes) ? passes[submitCreatedId] : {};
  const everyCallback = (current) => hasOwnProperty.call(result, current);
  const { executeRecaptcha } = useGoogleReCaptcha();

  if (submitSuccess && ['id', 'person_id'].every(everyCallback)) {
    const { id, person_id: { type, number: idNumber } } = result;
    const urlQuery = {
      pass_id: id,
      person_id_type: type,
      person_id_number: idNumber,
    };

    return (
      <Redirect
        to={{
          pathname: '/pass',
          search: `?${stringify(urlQuery)}`,
        }}
      />
    );
  }

  return (
    <section className="new-pass-page">
      <h1 className="h2">
        {formatMessage(messages.Title)}
        {showHeadingAsterisk && '*'}
      </h1>
      <ErrorAlert
        errorCode={optionsErrorCode}
        errorMessage={optionsErrorMessage}
      />
      <Col
        md={{ size: 10, offset: 1 }}
        lg={{ size: 6, offset: 3 }}
      >
        <Card>
          <CardBody>
            <PassRequestForm
              isPending={isPending}
              personIdTypes={personIdTypes}
              onSubmit={({ personIdType, personIdNumber, purposeId }) => {
                dispatch(
                  requestNewPassCall(personIdType, personIdNumber, purposeId, executeRecaptcha),
                );
              }}
              errorCode={submitErrorCode}
              errorMessage={submitErrorMessage}
              invalidFields={submitInvalidFields}
            />
          </CardBody>
          {submitErrorCode === 400 && submitErrorMessage && (
            <CardFooter className="text-danger text-center">
              <strong>
                {submitErrorMessage}
              </strong>
            </CardFooter>
          )}
        </Card>
      </Col>
    </section>
  );
};

NewPass.propTypes = {
  dispatch: func.isRequired,
  isPending: bool.isRequired,
  personIdTypes: arrayOf(
    shape({
      id: number,
      name: string,
      input: shape({
        label: string,
        aria_describedby: string,
        placeholder: string,
        maxlength: number,
        pattern: string,
      }),
    }),
  ).isRequired,
  passes: objectOf(
    shape({
      id: number,
      person_id: shape({
        type: number,
        number: string,
      }),
    }),
  ).isRequired,
  optionsErrorCode: number,
  optionsErrorMessage: string,
  submitErrorCode: number,
  submitErrorMessage: string,
  submitInvalidFields: arrayOf(
    shape({
      field_name: string,
      field_value: oneOfType([string, number]),
      field_error_message: string,
    }),
  ),
  submitSuccess: bool.isRequired,
  submitCreatedId: number,
  showHeadingAsterisk: bool,
};

NewPass.defaultProps = {
  optionsErrorCode: null,
  optionsErrorMessage: null,
  submitErrorCode: null,
  submitErrorMessage: null,
  submitInvalidFields: null,
  submitCreatedId: null,
  showHeadingAsterisk: false,
};

const mapStateToProps = ({
  settings: {
    isPending,
  },
  passOptions: {
    errorCode: optionsErrorCode,
    errorMessage: optionsErrorMessage,
  },
  passRequest: {
    errorCode: submitErrorCode,
    errorMessage: submitErrorMessage,
    invalidFields: submitInvalidFields,
    success: submitSuccess,
    createdId: submitCreatedId,
  },
  entities: {
    personIdTypes,
    passes,
  },
}) => ({
  isPending,
  personIdTypes,
  passes,
  optionsErrorCode,
  optionsErrorMessage,
  submitErrorCode,
  submitErrorMessage,
  submitInvalidFields,
  submitSuccess,
  submitCreatedId,
});

export default connect(mapStateToProps)(NewPass);
