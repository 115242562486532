import React from 'react';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Navbar, Nav } from 'reactstrap';

import Navigation from './Navigation';
import LangSwitch from './LangSwitch';
import Copyright from './Copyright';
import { setLocale, requestNewPassReset } from '../redux/actionCreators';

const Footer = ({
  dispatch,
  locale,
  isPending,
  contactGithub,
  contactEmail,
  showDemoNotice,
  appVersionShort,
  appVersionFull,
  buildTimestamp,
}) => (
  <>
    <Navbar dark expand="md">
      <Nav navbar fill>
        <Navigation
          onNewPassClick={() => {
            dispatch(requestNewPassReset());
          }}
        />
        <LangSwitch
          currentLang={locale}
          setLang={(newLocale) => {
            dispatch(setLocale(newLocale));
          }}
          disabled={isPending}
        />
      </Nav>
    </Navbar>
    <Copyright
      contactGithub={contactGithub}
      contactEmail={contactEmail}
      showDemoNotice={showDemoNotice}
      appVersionShort={appVersionShort}
      appVersionFull={appVersionFull}
      buildTimestamp={buildTimestamp}
    />
  </>
);

Footer.propTypes = {
  dispatch: func.isRequired,
  locale: string.isRequired,
  isPending: bool.isRequired,
  contactGithub: string,
  contactEmail: string,
  showDemoNotice: bool,
  appVersionShort: string,
  appVersionFull: string,
  buildTimestamp: string,
};

Footer.defaultProps = {
  contactGithub: null,
  contactEmail: null,
  showDemoNotice: true,
  appVersionShort: null,
  appVersionFull: null,
  buildTimestamp: null,
};

const mapStateToProps = ({ settings: { locale, isPending } }) => ({ locale, isPending });

export default connect(mapStateToProps)(Footer);
