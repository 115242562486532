/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InlineObject model module.
 * @module model/InlineObject
 * @version 1.3.3
 */
class InlineObject {
    /**
     * Constructs a new <code>InlineObject</code>.
     * @alias module:model/InlineObject
     * @param personIdType {Number} Person ID type from #/components/schemas/PersonIdType/id 
     * @param personIdNumber {String} Full number of person ID. Should by validated.
     * @param passPurposeId {Number} Purpose ID from #/components/schemas/PassPurpose/id 
     * @param gRecaptchaResponse {String} Google ReCaptcha response. Required on production server. Ref to official docs: [Verifying the user's response| ReCAPTCHA](https://developers.google.com/recaptcha/docs/verify) 
     */
    constructor(personIdType, personIdNumber, passPurposeId, gRecaptchaResponse) { 
        
        InlineObject.initialize(this, personIdType, personIdNumber, passPurposeId, gRecaptchaResponse);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, personIdType, personIdNumber, passPurposeId, gRecaptchaResponse) { 
        obj['person_id_type'] = personIdType;
        obj['person_id_number'] = personIdNumber;
        obj['pass_purpose_id'] = passPurposeId;
        obj['g-recaptcha-response'] = gRecaptchaResponse;
    }

    /**
     * Constructs a <code>InlineObject</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InlineObject} obj Optional instance to populate.
     * @return {module:model/InlineObject} The populated <code>InlineObject</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject();

            if (data.hasOwnProperty('person_id_type')) {
                obj['person_id_type'] = ApiClient.convertToType(data['person_id_type'], 'Number');
            }
            if (data.hasOwnProperty('person_id_number')) {
                obj['person_id_number'] = ApiClient.convertToType(data['person_id_number'], 'String');
            }
            if (data.hasOwnProperty('pass_purpose_id')) {
                obj['pass_purpose_id'] = ApiClient.convertToType(data['pass_purpose_id'], 'Number');
            }
            if (data.hasOwnProperty('g-recaptcha-response')) {
                obj['g-recaptcha-response'] = ApiClient.convertToType(data['g-recaptcha-response'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Person ID type from #/components/schemas/PersonIdType/id 
 * @member {Number} person_id_type
 */
InlineObject.prototype['person_id_type'] = undefined;

/**
 * Full number of person ID. Should by validated.
 * @member {String} person_id_number
 */
InlineObject.prototype['person_id_number'] = undefined;

/**
 * Purpose ID from #/components/schemas/PassPurpose/id 
 * @member {Number} pass_purpose_id
 */
InlineObject.prototype['pass_purpose_id'] = undefined;

/**
 * Google ReCaptcha response. Required on production server. Ref to official docs: [Verifying the user's response| ReCAPTCHA](https://developers.google.com/recaptcha/docs/verify) 
 * @member {String} g-recaptcha-response
 */
InlineObject.prototype['g-recaptcha-response'] = undefined;






export default InlineObject;

