import React from 'react';
import { string } from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import NewPassRoute from './NewPass';
import PassRoute from './Pass';
import Footer from '../components/Footer';
import RuMessages from '../../../build/ru.json';

const RootRoute = ({ locale }) => (
  <IntlProvider
    locale={locale}
    key={locale}
    defaultLocale="en"
    messages={(locale === 'ru') ? RuMessages : null}
  >
    <BrowserRouter>
      <Container tag="main">
        <Switch>
          <Route path="/pass">
            <PassRoute />
          </Route>
          <Route>
            {process.env.NODE_ENV !== 'development' && (
              // render captcha for production only
              <GoogleReCaptchaProvider
                reCaptchaKey={G_RECAPTCHA_SITEKEY}
                language={locale === 'ru' ? 'ru-RU' : 'en-US'}
                hey={locale}
              >
                <NewPassRoute
                  showHeadingAsterisk={APP_SHOW_DEMO_NOTICE}
                />
              </GoogleReCaptchaProvider>
            )}
            {process.env.NODE_ENV === 'development' && (
              <NewPassRoute
                showHeadingAsterisk={APP_SHOW_DEMO_NOTICE}
              />
            )}
          </Route>
        </Switch>
      </Container>
      <Container tag="footer" fluid>
        <Container>
          <Footer
            contactGithub={AUTHOR_GITHUB}
            contactEmail={AUTHOR_EMAIL}
            appVersionShort={APP_VERSION_SHORT}
            appVersionFull={APP_VERSION_FULL}
            buildTimestamp={APP_BUILD_TS}
            showDemoNotice={APP_SHOW_DEMO_NOTICE}
          />
        </Container>
      </Container>
    </BrowserRouter>
  </IntlProvider>
);

RootRoute.propTypes = {
  locale: string.isRequired,
};

const mapStateToProps = ({ settings: { locale } }) => ({ locale });

export default connect(mapStateToProps)(RootRoute);
