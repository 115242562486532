import React from 'react';
import { render } from 'react-dom';
import App from './js/App';

import './scss/index.scss';

const root = document.createElement('div');
root.id = 'app-root';
document.body.appendChild(root);

render(<App />, root);
