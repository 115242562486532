import React from 'react';
import { NavItem, NavLink as BsNavLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { func } from 'prop-types';

const messages = defineMessages({
  NewPassLinkLabel: {
    defaultMessage: 'New Pass',
  },
  ApiDocsLinkLabel: {
    defaultMessage: 'API docs',
  },
});

const Navigation = ({ onNewPassClick }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <NavItem>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/"
          exact
          onClick={() => {
            if (typeof onNewPassClick === 'function') {
              onNewPassClick();
            }
            return true;
          }}
        >
          {formatMessage(messages.NewPassLinkLabel)}
        </NavLink>
      </NavItem>
      <NavItem>
        <BsNavLink
          href="/api-docs"
          target="_blank"
        >
          {formatMessage(messages.ApiDocsLinkLabel)}
        </BsNavLink>
      </NavItem>
    </>
  );
};

Navigation.propTypes = {
  onNewPassClick: func,
};

Navigation.defaultProps = {
  onNewPassClick: null,
};

export default Navigation;
