import { createActions, createAction } from 'redux-actions';
import ApiPayloadKey from './middlewares/ApiPayloadKey';

/* eslint-disable import/prefer-default-export */
export const {
  setLocale,
  getPassOptionsSuccess,
  getPassOptionsError,
  requestNewPassCall,
  requestNewPassSuccess,
  requestNewPassError,
  requestNewPassReset,
  findPassCall,
  findPassSuccess,
  findPassError,
} = createActions({
  SET_LOCALE: (locale) => ({ locale }),
  GET_PASS_OPTIONS_SUCCESS: (data) => ({ data }),
  GET_PASS_OPTIONS_ERROR: (error) => ({ error }),
  REQUEST_NEW_PASS_CALL: (personIdType, personIdNumber, purposeId, executeRecaptcha) => ({
    [ApiPayloadKey]: {
      successActionCreator: requestNewPassSuccess,
      errorActionCreator: requestNewPassError,
      options: {
        inlineObject: {
          person_id_type: personIdType,
          person_id_number: personIdNumber,
          pass_purpose_id: purposeId,
        },
      },
      executeRecaptcha,
    },
  }),
  REQUEST_NEW_PASS_SUCCESS: (data) => ({ data }),
  REQUEST_NEW_PASS_ERROR: (error) => ({ error }),
  REQUEST_NEW_PASS_RESET: () => ({}),
  FIND_PASS_CALL: (passId, personIdType, personIdNumber) => ({
    [ApiPayloadKey]: {
      successActionCreator: findPassSuccess,
      errorActionCreator: findPassError,
      arguments: [passId, personIdType, personIdNumber],
    },
  }),
  FIND_PASS_SUCCESS: (data) => ({ data }),
  FIND_PASS_ERROR: (error) => ({ error }),
});

// redux-actions package doesn't support thunk middleware
// https://github.com/redux-utilities/redux-actions/issues/149
// workaround
const GET_PASS_OPTIONS_CALL = 'GET_PASS_OPTIONS_CALL';
const getPassOptionsCallActionCreator = createAction(GET_PASS_OPTIONS_CALL, () => ({
  [ApiPayloadKey]: {
    successActionCreator: getPassOptionsSuccess,
    errorActionCreator: getPassOptionsError,
  },
}));
const getPassOptionsCallCallback = () => (dispatch, getState) => {
  const { settings: { isPending } } = getState();
  if (isPending) return null;

  return dispatch(getPassOptionsCallActionCreator());
};
getPassOptionsCallCallback.toString = () => GET_PASS_OPTIONS_CALL;

export const getPassOptionsCall = getPassOptionsCallCallback;
