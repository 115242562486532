import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';

import PurposeSelector from './PurposeSelector';
import { getPassOptionsCall } from '../../redux/actionCreators';
import {
  getLocale,
  getIsPending,
  canCallGetPassOptions,
  getPurposes,
} from '../../redux/selectors';

const PurposeLoader = ({ isInvalid, required }) => {
  const dispatch = useDispatch();
  const locale = useSelector(getLocale);
  const isPassOptionsNotLoaded = useSelector(canCallGetPassOptions);

  useEffect(() => {
    if (isPassOptionsNotLoaded) {
      dispatch(getPassOptionsCall());
    }
  }, [locale, isPassOptionsNotLoaded, dispatch]);

  return (
    <PurposeSelector
      purposes={useSelector(getPurposes)}
      disabled={useSelector(getIsPending)}
      required={required}
      isInvalid={isInvalid}
    />
  );
};

PurposeLoader.propTypes = {
  isInvalid: bool,
  required: bool,
};

PurposeLoader.defaultProps = {
  isInvalid: false,
  required: false,
};

export default PurposeLoader;
