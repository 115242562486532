/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PassPersonId from './PassPersonId';
import PassPurpose from './PassPurpose';

/**
 * The Pass model module.
 * @module model/Pass
 * @version 1.3.3
 */
class Pass {
    /**
     * Constructs a new <code>Pass</code>.
     * Issued pass.
     * @alias module:model/Pass
     * @param id {Number} Internal database ID.
     * @param from {Date} When pass has been issued. Date time value with time zone. ISO8601 date string. 
     * @param expires {Date} When pass expires. Date time value with time zone. ISO8601 date string. 
     * @param personId {module:model/PassPersonId} 
     * @param purpose {module:model/PassPurpose} 
     * @param qrcodeSrc {String} QR code `img` attribute `src`. Decoded QRCode contains direct link to issued pass. 
     */
    constructor(id, from, expires, personId, purpose, qrcodeSrc) { 
        
        Pass.initialize(this, id, from, expires, personId, purpose, qrcodeSrc);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, from, expires, personId, purpose, qrcodeSrc) { 
        obj['id'] = id;
        obj['from'] = from;
        obj['expires'] = expires;
        obj['person_id'] = personId;
        obj['purpose'] = purpose;
        obj['qrcode_src'] = qrcodeSrc;
    }

    /**
     * Constructs a <code>Pass</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Pass} obj Optional instance to populate.
     * @return {module:model/Pass} The populated <code>Pass</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Pass();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('from')) {
                obj['from'] = ApiClient.convertToType(data['from'], 'Date');
            }
            if (data.hasOwnProperty('expires')) {
                obj['expires'] = ApiClient.convertToType(data['expires'], 'Date');
            }
            if (data.hasOwnProperty('person_id')) {
                obj['person_id'] = PassPersonId.constructFromObject(data['person_id']);
            }
            if (data.hasOwnProperty('purpose')) {
                obj['purpose'] = PassPurpose.constructFromObject(data['purpose']);
            }
            if (data.hasOwnProperty('qrcode_src')) {
                obj['qrcode_src'] = ApiClient.convertToType(data['qrcode_src'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Internal database ID.
 * @member {Number} id
 */
Pass.prototype['id'] = undefined;

/**
 * When pass has been issued. Date time value with time zone. ISO8601 date string. 
 * @member {Date} from
 */
Pass.prototype['from'] = undefined;

/**
 * When pass expires. Date time value with time zone. ISO8601 date string. 
 * @member {Date} expires
 */
Pass.prototype['expires'] = undefined;

/**
 * @member {module:model/PassPersonId} person_id
 */
Pass.prototype['person_id'] = undefined;

/**
 * @member {module:model/PassPurpose} purpose
 */
Pass.prototype['purpose'] = undefined;

/**
 * QR code `img` attribute `src`. Decoded QRCode contains direct link to issued pass. 
 * @member {String} qrcode_src
 */
Pass.prototype['qrcode_src'] = undefined;






export default Pass;

