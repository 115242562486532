/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PassPurpose from './PassPurpose';
import PersonIdType from './PersonIdType';

/**
 * The InlineResponse200 model module.
 * @module model/InlineResponse200
 * @version 1.3.3
 */
class InlineResponse200 {
    /**
     * Constructs a new <code>InlineResponse200</code>.
     * @alias module:model/InlineResponse200
     * @param personIdTypes {Array.<module:model/PersonIdType>} 
     * @param purposes {Array.<module:model/PassPurpose>} 
     */
    constructor(personIdTypes, purposes) { 
        
        InlineResponse200.initialize(this, personIdTypes, purposes);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, personIdTypes, purposes) { 
        obj['person_id_types'] = personIdTypes;
        obj['purposes'] = purposes;
    }

    /**
     * Constructs a <code>InlineResponse200</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InlineResponse200} obj Optional instance to populate.
     * @return {module:model/InlineResponse200} The populated <code>InlineResponse200</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineResponse200();

            if (data.hasOwnProperty('person_id_types')) {
                obj['person_id_types'] = ApiClient.convertToType(data['person_id_types'], [PersonIdType]);
            }
            if (data.hasOwnProperty('purposes')) {
                obj['purposes'] = ApiClient.convertToType(data['purposes'], [PassPurpose]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/PersonIdType>} person_id_types
 */
InlineResponse200.prototype['person_id_types'] = undefined;

/**
 * @member {Array.<module:model/PassPurpose>} purposes
 */
InlineResponse200.prototype['purposes'] = undefined;






export default InlineResponse200;

