/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PassPurpose model module.
 * @module model/PassPurpose
 * @version 1.3.3
 */
class PassPurpose {
    /**
     * Constructs a new <code>PassPurpose</code>.
     * Purpose of requested pass.
     * @alias module:model/PassPurpose
     * @param id {Number} Internal database ID.
     * @param name {String} Name of purpose. Non-localized value for developers only.
     * @param duration {String} Duration time. Pass is temporary. Should be valid time value in a format `hhh:mm:ss`. 
     * @param optionLabel {String} Localized label for select `<option>` tag.
     */
    constructor(id, name, duration, optionLabel) { 
        
        PassPurpose.initialize(this, id, name, duration, optionLabel);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, duration, optionLabel) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['duration'] = duration;
        obj['option_label'] = optionLabel;
    }

    /**
     * Constructs a <code>PassPurpose</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PassPurpose} obj Optional instance to populate.
     * @return {module:model/PassPurpose} The populated <code>PassPurpose</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PassPurpose();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('duration')) {
                obj['duration'] = ApiClient.convertToType(data['duration'], 'String');
            }
            if (data.hasOwnProperty('option_label')) {
                obj['option_label'] = ApiClient.convertToType(data['option_label'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Internal database ID.
 * @member {Number} id
 */
PassPurpose.prototype['id'] = undefined;

/**
 * Name of purpose. Non-localized value for developers only.
 * @member {String} name
 */
PassPurpose.prototype['name'] = undefined;

/**
 * Duration time. Pass is temporary. Should be valid time value in a format `hhh:mm:ss`. 
 * @member {String} duration
 */
PassPurpose.prototype['duration'] = undefined;

/**
 * Localized label for select `<option>` tag.
 * @member {String} option_label
 */
PassPurpose.prototype['option_label'] = undefined;






export default PassPurpose;

