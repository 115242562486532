import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers';
import ApiMiddleware from './middlewares/ApiMiddleware';

export default function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware, ApiMiddleware];
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable global-require */
    const { logger } = require('redux-logger');

    middlewares.push(logger);
  }

  return createStore(rootReducer, preloadedState, applyMiddleware(...middlewares));
}
