import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import {
  FormGroup,
  CustomInput,
  Label,
  FormFeedback,
} from 'reactstrap';
import {
  bool,
  string,
  number,
  shape,
  arrayOf,
} from 'prop-types';

const messages = defineMessages({
  Label: {
    defaultMessage: 'Person ID type',
  },
  EmptyOptionAriaLabel: {
    defaultMessage: 'Not selected',
  },
});

const PersonIdTypeSelector = ({
  personIdTypes,
  disabled,
  required,
  isInvalid,
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormGroup>
      <Label for="personIdType">
        {formatMessage(messages.Label)}
      </Label>
      <Field name="personIdType">
        {({ field }) => (
          <CustomInput
            /* eslint-disable react/jsx-props-no-spreading */
            {...field}
            /* eslint-enable react/jsx-props-no-spreading */
            type="select"
            id="personIdType"
            invalid={isInvalid}
            required={required}
            disabled={disabled}
          >
            <option
              aria-label={formatMessage(messages.EmptyOptionAriaLabel)}
              value=""
            />
            {personIdTypes.map(({ id, name, input: { label } }) => (
              <option
                key={name}
                value={id}
              >
                {label}
              </option>
            ))}
          </CustomInput>
        )}
      </Field>
      <FormFeedback>
        <ErrorMessage name="personIdType" />
      </FormFeedback>
    </FormGroup>
  );
};

PersonIdTypeSelector.propTypes = {
  personIdTypes: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      input: shape({
        label: string,
        aria_describedby: string,
        placeholder: string,
        maxlength: number,
        pattern: string,
      }),
    }),
  ).isRequired,
  disabled: bool,
  required: bool,
  isInvalid: bool,
};

PersonIdTypeSelector.defaultProps = {
  disabled: false,
  required: false,
  isInvalid: false,
};

export default PersonIdTypeSelector;
