/**
 * Quarantine Pass API
 * API for basic quarantine pass system.
 *
 * The version of the OpenAPI document: 1.3.3
 * Contact: yura-bely@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import InlineObject from '../model/InlineObject';
import InlineResponse200 from '../model/InlineResponse200';
import Pass from '../model/Pass';

/**
* Main service.
* @module api/MainApi
* @version 1.3.3
*/
export default class MainApi {

    /**
    * Constructs a new MainApi. 
    * @alias module:api/MainApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Find existed pass.
     * Retrieves pass entity from storage. Search by pass ID only is disabled to avoid brute force attacks. 
     * @param {Number} passId Pass ID from #/components/schemas/Pass/id 
     * @param {Number} personIdType Person ID type from #/components/schemas/PersonIdType/id 
     * @param {String} personIdNumber Full number of person ID. Should by validated.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.acceptLanguage Preferred content language
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Pass} and HTTP response
     */
    findPassWithHttpInfo(passId, personIdType, personIdNumber, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'passId' is set
      if (passId === undefined || passId === null) {
        throw new Error("Missing the required parameter 'passId' when calling findPass");
      }
      // verify the required parameter 'personIdType' is set
      if (personIdType === undefined || personIdType === null) {
        throw new Error("Missing the required parameter 'personIdType' when calling findPass");
      }
      // verify the required parameter 'personIdNumber' is set
      if (personIdNumber === undefined || personIdNumber === null) {
        throw new Error("Missing the required parameter 'personIdNumber' when calling findPass");
      }

      let pathParams = {
      };
      let queryParams = {
        'pass_id': passId,
        'person_id_type': personIdType,
        'person_id_number': personIdNumber
      };
      let headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'image/svg+xml'];
      let returnType = Pass;
      return this.apiClient.callApi(
        '/pass', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find existed pass.
     * Retrieves pass entity from storage. Search by pass ID only is disabled to avoid brute force attacks. 
     * @param {Number} passId Pass ID from #/components/schemas/Pass/id 
     * @param {Number} personIdType Person ID type from #/components/schemas/PersonIdType/id 
     * @param {String} personIdNumber Full number of person ID. Should by validated.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.acceptLanguage Preferred content language
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Pass}
     */
    findPass(passId, personIdType, personIdNumber, opts) {
      return this.findPassWithHttpInfo(passId, personIdType, personIdNumber, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Retrieves all options to request new pass.
     * These options required to render valid web form.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.acceptLanguage Preferred content language
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    getPassOptionsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/pass_options', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Retrieves all options to request new pass.
     * These options required to render valid web form.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.acceptLanguage Preferred content language
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    getPassOptions(opts) {
      return this.getPassOptionsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Request new pass.
     * Checks provided document and issues new pass. Limitations: * User cannot request new pass when got active one. * Do not issue pass with the same purpose within 24 hours. That time range starts from issued timestamp (`from` table column). * Max 3 passes per day for each user. 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.acceptLanguage Preferred content language
     * @param {module:model/InlineObject} opts.inlineObject 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Pass} and HTTP response
     */
    requestNewPassWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['inlineObject'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Pass;
      return this.apiClient.callApi(
        '/pass', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Request new pass.
     * Checks provided document and issues new pass. Limitations: * User cannot request new pass when got active one. * Do not issue pass with the same purpose within 24 hours. That time range starts from issued timestamp (`from` table column). * Max 3 passes per day for each user. 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.acceptLanguage Preferred content language
     * @param {module:model/InlineObject} opts.inlineObject 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Pass}
     */
    requestNewPass(opts) {
      return this.requestNewPassWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
