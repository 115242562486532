import React from 'react';
import { Alert } from 'reactstrap';
import { useIntl, defineMessages } from 'react-intl';
import { number, string } from 'prop-types';

const messages = defineMessages({
  ErrorTitle: {
    defaultMessage: 'Ooops, something went wrong.',
  },
  ErrorCode: {
    defaultMessage: 'Code: {errorCode}',
  },
  ErrorMessage: {
    defaultMessage: 'Message: "{errorMessage}"',
  },
});

const ErrorAlert = ({ errorCode, errorMessage }) => {
  const { formatMessage } = useIntl();

  return (
    (errorCode !== null || errorMessage !== null) && (
      <Alert color="danger">
        <div>
          <strong>
            {formatMessage(messages.ErrorTitle)}
          </strong>
        </div>
        {(errorCode !== null) && (
          <div>
            {formatMessage(messages.ErrorCode, { errorCode })}
          </div>
        )}
        {(errorMessage !== null) && (
          <div>
            {formatMessage(messages.ErrorMessage, { errorMessage })}
          </div>
        )}
      </Alert>
    )
  );
};

ErrorAlert.propTypes = {
  errorCode: number,
  errorMessage: string,
};

ErrorAlert.defaultProps = {
  errorCode: null,
  errorMessage: null,
};

export default ErrorAlert;
