import { handleActions } from 'redux-actions';

import {
  setLocale,
  requestNewPassCall,
  requestNewPassSuccess,
  requestNewPassError,
  requestNewPassReset,
  findPassCall,
  findPassSuccess,
  findPassError,
} from '../actionCreators';

const initState = {
  errorCode: null,
  errorMessage: null,
  invalidFields: null,
  success: false,
  createdId: null,
};

const resetReducer = () => initState;

const requestSuccessReducer = (state) => ({
  ...state,
  errorCode: null,
  errorMessage: null,
  invalidFields: null,
  success: true,
  createdId: null,
});

const requestSuccessSubmitReducer = (state, { payload: { data } }) => ({
  ...state,
  ...requestSuccessReducer(state),
  createdId: data.id,
});

const requestErrorReducer = (
  state, {
    payload: {
      error: {
        errorCode,
        errorMessage,
        invalidFields = null,
      },
    },
  },
) => ({
  ...state,
  errorCode,
  errorMessage,
  invalidFields,
  success: false,
  createdId: null,
});

export default handleActions(
  {
    [setLocale]: resetReducer,

    [requestNewPassCall]: resetReducer,
    [requestNewPassError]: requestErrorReducer,
    [requestNewPassSuccess]: requestSuccessSubmitReducer,
    [requestNewPassReset]: resetReducer,

    [findPassCall]: resetReducer,
    [findPassSuccess]: requestSuccessReducer,
    [findPassError]: requestErrorReducer,
  },
  initState,
);
