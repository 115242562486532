import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  FormText,
} from 'reactstrap';
import {
  bool,
  string,
  number,
} from 'prop-types';

const messages = defineMessages({
  Label: {
    defaultMessage: '{PersonIdLabel} number',
  },
  UndefinedLabel: {
    defaultMessage: 'Document number',
  },
});

const PersonIdNumberInput = ({
  isPending,
  inputLabel,
  inputAriaDescribedby,
  required,
  disabled,
  isInvalid,
  placeholder,
  maxLength,
  pattern,
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormGroup>
      <Label for="personIdNumber">
        {inputLabel && (
          <>
            {formatMessage(messages.Label, { PersonIdLabel: inputLabel })}
          </>
        )}
        {inputLabel === null && (
          <>
            {formatMessage(messages.UndefinedLabel)}
          </>
        )}
      </Label>
      <Field name="personIdNumber">
        {({ field }) => (
          <Input
            /* eslint-disable react/jsx-props-no-spreading */
            {...field}
            /* eslint-enable react/jsx-props-no-spreading */
            id="personIdNumber"
            placeholder={placeholder}
            maxLength={maxLength}
            pattern={pattern}
            disabled={disabled || isPending}
            invalid={isInvalid}
            required={required}
          />
        )}
      </Field>
      <FormFeedback>
        <ErrorMessage name="personIdNumber" />
      </FormFeedback>
      <FormText color="muted">
        {inputAriaDescribedby}
      </FormText>
    </FormGroup>
  );
};

PersonIdNumberInput.propTypes = {
  isPending: bool.isRequired,
  inputLabel: string,
  inputAriaDescribedby: string,
  required: bool,
  disabled: bool,
  isInvalid: bool,
  placeholder: string,
  maxLength: number,
  pattern: string,
};

PersonIdNumberInput.defaultProps = {
  inputLabel: null,
  inputAriaDescribedby: null,
  required: false,
  disabled: false,
  isInvalid: false,
  placeholder: null,
  maxLength: null,
  pattern: null,
};

const mapStateToProps = ({ settings: { isPending } }) => ({ isPending });

export default connect(mapStateToProps)(PersonIdNumberInput);
