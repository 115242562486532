import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl, defineMessages } from 'react-intl';
import { bool, string } from 'prop-types';

const messages = defineMessages({
  DemoNotice: {
    defaultMessage: 'Important! This is just a demo project, not a real system.',
  },
  DevelopedBy: {
    defaultMessage: 'Developed by Yuriy Belenko, checkout my {github} or write me {email}.',
  },
  GithubLinkLabel: {
    defaultMessage: 'GitHub account',
  },
  EmailLinkLabel: {
    defaultMessage: 'Write an email',
  },
  AppVersion: {
    defaultMessage: 'version',
  },
  AppBuildTimestamp: {
    defaultMessage: 'from',
  },
});

const Copyright = ({
  contactGithub,
  contactEmail,
  showDemoNotice,
  appVersionShort,
  appVersionFull,
  buildTimestamp,
}) => {
  const { formatMessage } = useIntl();
  const debugInfo = {};
  const {
    DemoNotice,
    DevelopedBy,
    GithubLinkLabel,
    EmailLinkLabel,
  } = messages;

  if (appVersionShort) {
    debugInfo[formatMessage(messages.AppVersion)] = appVersionShort;
  }
  if (buildTimestamp) {
    debugInfo[formatMessage(messages.AppBuildTimestamp)] = moment(buildTimestamp).fromNow();
  }

  return (
    <div className="footnote">
      {showDemoNotice && (
        <div>
          *&nbsp;
          {formatMessage(DemoNotice)}
        </div>
      )}
      {contactGithub && contactEmail && (
        <div>
          {formatMessage(DevelopedBy, {
            github: (
              <a
                href={contactGithub}
                target="_blank"
                aria-label={formatMessage(GithubLinkLabel)}
                rel="noopener noreferrer"
                key="author-github"
              >
                <FontAwesomeIcon icon={['fab', 'github']} />
              </a>
            ),
            email: (
              <a
                href={`mailto:${contactEmail}`}
                aria-label={formatMessage(EmailLinkLabel)}
                key="author-email"
              >
                <FontAwesomeIcon icon="envelope" />
              </a>
            ),
          })}
        </div>
      )}
      {(appVersionShort || buildTimestamp) && (
        <div
          className="version"
          title={appVersionFull}
        >
          <code>
            {JSON.stringify(debugInfo)}
          </code>
        </div>
      )}
    </div>
  );
};

Copyright.propTypes = {
  contactGithub: string,
  contactEmail: string,
  showDemoNotice: bool,
  appVersionShort: string,
  appVersionFull: string,
  buildTimestamp: string,
};

Copyright.defaultProps = {
  contactGithub: null,
  contactEmail: null,
  showDemoNotice: false,
  appVersionShort: null,
  appVersionFull: null,
  buildTimestamp: null,
};

export default Copyright;
